import { Button, Divider, DropdownItemProps, DropdownProps, Form, Grid, Header, List, Message, Modal } from "semantic-ui-react";
import { ClientPolicyUpload, ClientPolicyUploadFile, Portfolio } from "../../types";
import React, { useContext, useEffect, useState } from "react";
import { Upload, UploadFileInfo, UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent } from "@progress/kendo-react-upload";
import { genderDdlOptions, uploadFileRestrictions } from "../../services/Constants";
import { isValidSSN, toIsoDateString } from "../../services/Library";

import { AppContext } from "../../components/AppContextProvider";
import NumberFormat from "react-number-format";
import config from "../../config";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import useDatabroker from "../../hooks/useDatabroker";
import useSproc from "../../hooks/useSproc";

const defaultClientPolicyUpload: ClientPolicyUpload = {
    PolicyNumber: "",
    ClientExtID: "",
    PortfolioID: 0,
    CompanyID: 0,
    CompanyName: "",
    FaceValue: "",
    Insured1FirstName: "",
    Insured1LastName: "",
    Insured1DOB: "",
    Insured1SSN: "",
    Insured1Gender: "",
    Files: [],
    Notes: ""
};

interface ClientPolicyUploadModalProps {
    setIsModalOpen: (data: boolean) => void;
    getClientPolicyUploadQueue: () => Promise<void>;
}
interface DupeCheckResponse {
    ErrorMessage: string | null;
}

const ClientPolicyUploadModal: React.FC<ClientPolicyUploadModalProps> = (props: ClientPolicyUploadModalProps) => {
    const { setIsModalOpen, getClientPolicyUploadQueue } = props;
    const [clientPolicyUpload, setClientPolicyUpload] = useState<ClientPolicyUpload>(defaultClientPolicyUpload);
    const [filesToUpload, setFilesToUpload] = useState<UploadFileInfo[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<ClientPolicyUploadFile[]>([]);
    const [token, setToken] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [portfolioOptions, setPortfolioOptions] = useState<DropdownItemProps[]>();
    const [carrierOptions, setCarrierOptions] = useState<DropdownItemProps[]>();
    const [showSecondInsured, setShowSecondInsured] = useState<boolean>(false);
    const [gender1Error, setGender1Error] = useState<boolean>(false);
    const [gender2Error, setGender2Error] = useState<boolean>(false);
    const [insured1SSNError, setInsured1SSNError] = useState<boolean>(false);
    const [insured2SSNError, setInsured2SSNError] = useState<boolean>(false);
    const [portfolioError, setPortfolioError] = useState<boolean>(false);
    const [carrierError, setCarrierError] = useState<boolean>(false);
    const [duplicatePolicyError, setDuplicatePolicyError] = useState<string | null>(null);
    const { portfolios } = useContext(AppContext);

    const databroker = useDatabroker();
    const today: string = toIsoDateString(new Date());
    const addSecond = "Add Second Insured";
    const removeSecond = "Remove Second Insured";
    const { getAccessTokenSilently } = useAuth0();
    const sproc = useSproc();

    useEffect(() => {
        if (!portfolios || portfolioOptions) return;
        setPortfolioOptions(
            portfolios.map((portfolio: Portfolio) => {
                return {
                    key: portfolio.PortfolioID,
                    value: portfolio.PortfolioID,
                    name: portfolio.PortfolioName,
                    text: `${portfolio.PortfolioName} (${portfolio.PortfolioID})`
                };
            })
        );
        if (!portfolios || portfolioOptions) return;
    }, [portfolios, portfolioOptions]);

    useEffect(() => {
        (async () => {
            try {
                if (carrierOptions) return;
                const { data, error } = await databroker.sproc({
                    objectName: "GetCarriers"
                });
                if (error || !data) {
                    return;
                }
                setCarrierOptions(
                    data.map((item) => {
                        return {
                            text: item.CompanyName,
                            value: item.CompanyID,
                            key: item.CompanyID
                        };
                    })
                );
            } catch (err) {
                toast.error("Error getting carrier options");
            }
        })();
    }, [databroker, carrierOptions]);

    useEffect(() => {
        (async () => {
            setToken(await getAccessTokenSilently());
        })();
    }, [getAccessTokenSilently]);

    const handleSubmit = async () => {
        if (!uploadedFiles.length) {
            toast.error("At least one file must be uploaded");
            return;
        }
        if (!clientPolicyUpload.PortfolioID) {
            setPortfolioError(true);
            return;
        }

        if (!clientPolicyUpload.CompanyID) {
            setCarrierError(true);
            return;
        }

        if (!clientPolicyUpload.Insured1Gender) {
            setGender1Error(true);
            return;
        }

        if (showSecondInsured && !clientPolicyUpload.Insured2Gender) {
            setGender2Error(true);
            return;
        }

        setInsured1SSNError(false);
        if (!isValidSSN(clientPolicyUpload.Insured1SSN)) {
            setInsured1SSNError(true);
            return;
        }
        setInsured2SSNError(false);
        if (showSecondInsured && !isValidSSN(clientPolicyUpload.Insured2SSN || "")) {
            setInsured2SSNError(true);
            return;
        }

        setLoading(true);
        const previousDuplicate = duplicatePolicyError;
        const DupeCheckParams = {
            UserID: "USERID",
            PolicyNumber: clientPolicyUpload.PolicyNumber.trim(),
            PortfolioID: clientPolicyUpload.PortfolioID
        };

        setDuplicatePolicyError(null);

        const [{ ErrorMessage }] = await sproc<DupeCheckResponse>("CheckClientPolicyUploadDuplicates", DupeCheckParams);
        const duplicateOverride = previousDuplicate && ErrorMessage;
        if (ErrorMessage) {
            setDuplicatePolicyError(ErrorMessage);
            if (!duplicateOverride) {
                setLoading(false);
                return;
            }
        }

        let FaceValue = clientPolicyUpload.FaceValue;
        if (FaceValue === "") {
            FaceValue = null;
        }

        const params = {
            PolicyNumber: clientPolicyUpload.PolicyNumber.trim(),
            PortfolioID: clientPolicyUpload.PortfolioID,
            CompanyID: clientPolicyUpload.CompanyID,
            ClientExtID: clientPolicyUpload.ClientExtID?.trim() || null,
            FaceValue: FaceValue ?? null,
            Insured1FirstName: clientPolicyUpload.Insured1FirstName.trim(),
            Insured1LastName: clientPolicyUpload.Insured1LastName.trim(),
            Insured1DOB: clientPolicyUpload.Insured1DOB,
            Insured1SSN: clientPolicyUpload.Insured1SSN,
            Insured1Gender: clientPolicyUpload.Insured1Gender,
            Insured2FirstName: clientPolicyUpload.Insured2FirstName?.trim() || null,
            Insured2LastName: clientPolicyUpload.Insured2LastName?.trim() || null,
            Insured2DOB: clientPolicyUpload.Insured2DOB || null,
            Insured2SSN: clientPolicyUpload.Insured2SSN || null,
            Insured2Gender: clientPolicyUpload.Insured2Gender || null,
            Notes: clientPolicyUpload.Notes.trim() || null,
            Files: uploadedFiles.map((file) => {
                return { col1: file.FileName, col2: file.FileKey };
            })
        };

        try {
            await sproc<ClientPolicyUpload>("ClientPolicyUpload", params);
            toast.success("Policy submitted");
            setIsModalOpen(false);
            await getClientPolicyUploadQueue();
        } catch (error) {
            console.error(error);
            toast.error("Error uploading policy");
        } finally {
            setLoading(false);
        }
    };

    const onBeforeUpload = async (e: UploadOnBeforeUploadEvent) => {
        if (!token) {
            toast.error("Unable to obtain access token before upload");
            return;
        }
        e.headers = {
            Authorization: `Bearer ${token}`
        };
        e.additionalData = {
            type: "ClientPolicyUpload"
        };
    };

    const handleStatusChange = async (event: UploadOnStatusChangeEvent) => {
        const { response: data, affectedFiles, newState } = event;

        if (data && data.response && data.response.fileKey) {
            const [affectedFile] = affectedFiles;
            setFilesToUpload(filesToUpload.filter((file) => file.uid !== affectedFile.uid));
            const file: ClientPolicyUploadFile = { FileName: affectedFile.name, FileKey: data.response.fileKey };
            setUploadedFiles([...uploadedFiles, file]);
        } else {
            setFilesToUpload(newState);
        }
    };

    const clearSecondInsured = () => {
        setClientPolicyUpload({
            ...clientPolicyUpload,
            Insured2FirstName: undefined,
            Insured2LastName: undefined,
            Insured2DOB: undefined,
            Insured2SSN: undefined,
            Insured2Gender: undefined
        });
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
            as={Form}
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={handleClose}
            onSubmit={handleSubmit}
            size="small"
            open
        >
            <Modal.Header>Policy Upload</Modal.Header>
            <Modal.Content scrolling>
                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        label="Policy Number"
                        onChange={(_e, { value }) => setClientPolicyUpload({ ...clientPolicyUpload, PolicyNumber: value })}
                        placeholder="Policy Number"
                        required
                        error={!!duplicatePolicyError}
                        type="text"
                        value={clientPolicyUpload.PolicyNumber}
                    />
                    <Form.Input
                        fluid
                        label="Client External ID"
                        onChange={(_e, { value }) => setClientPolicyUpload({ ...clientPolicyUpload, ClientExtID: value })}
                        placeholder="Client External ID"
                        type="text"
                        value={clientPolicyUpload.ClientExtID || ""}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Dropdown
                        fluid
                        label="Portfolio"
                        options={portfolioOptions || []}
                        required
                        search
                        selection
                        error={portfolioError}
                        value={clientPolicyUpload.PortfolioID}
                        onChange={(_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
                            setClientPolicyUpload({
                                ...clientPolicyUpload,
                                PortfolioID: data.value as number
                            });
                            setPortfolioError(false);
                        }}
                    />
                    <Form.Dropdown
                        closeOnEscape
                        fluid
                        label="Carrier"
                        openOnFocus={false}
                        options={carrierOptions || []}
                        placeholder="Choose a carrier"
                        required
                        scrolling
                        search
                        selection
                        selectOnBlur={false}
                        error={carrierError}
                        value={clientPolicyUpload.CompanyID}
                        onChange={(e, { value }) => {
                            setClientPolicyUpload({ ...clientPolicyUpload, CompanyID: value as number });
                            setCarrierError(false);
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <NumberFormat
                        customInput={Form.Input}
                        decimalScale={2}
                        fixedDecimalScale
                        icon="usd"
                        iconPosition="left"
                        label="Face Value"
                        placeholder="Face Value"
                        thousandSeparator
                        width={8}
                        value={clientPolicyUpload.FaceValue ?? ""}
                        onValueChange={({ value }) =>
                            setClientPolicyUpload({ ...clientPolicyUpload, FaceValue: value === "" ? null : Number(value) })
                        }
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Input
                        label="Insured 1 First Name"
                        onChange={(e) => setClientPolicyUpload({ ...clientPolicyUpload, Insured1FirstName: e.target.value })}
                        required
                        value={clientPolicyUpload.Insured1FirstName}
                    />
                    <Form.Input
                        label="Insured 1 Last Name"
                        onChange={(e) => setClientPolicyUpload({ ...clientPolicyUpload, Insured1LastName: e.target.value })}
                        required
                        value={clientPolicyUpload.Insured1LastName}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        label="Insured 1 Date of Birth"
                        min={"1900-01-01"}
                        max={today}
                        onChange={(e) => setClientPolicyUpload({ ...clientPolicyUpload, Insured1DOB: e.target.value })}
                        required
                        type="date"
                        value={clientPolicyUpload.Insured1DOB || ""}
                    />
                    <NumberFormat
                        customInput={Form.Input}
                        fluid
                        format="###-##-####"
                        label="Insured 1 SSN"
                        error={insured1SSNError && "Invalid SSN"}
                        mask="_"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setClientPolicyUpload({ ...clientPolicyUpload, Insured1SSN: e.target.value });
                            setInsured1SSNError(false);
                        }}
                        placeholder="___-__-____"
                        required
                        value={clientPolicyUpload.Insured1SSN}
                    />
                    <Form.Dropdown
                        fluid
                        label="Insured 1 Gender"
                        onChange={(e, { value }) => {
                            setClientPolicyUpload({ ...clientPolicyUpload, Insured1Gender: value as string });
                            setGender1Error(false);
                        }}
                        error={gender1Error}
                        openOnFocus={false}
                        options={genderDdlOptions}
                        placeholder="Gender"
                        required
                        search
                        selection
                        selectOnBlur={false}
                        value={clientPolicyUpload.Insured1Gender}
                    />
                </Form.Group>
                <Button
                    content={showSecondInsured ? removeSecond : addSecond}
                    icon={showSecondInsured ? "minus" : "plus"}
                    primary
                    size="mini"
                    type="button"
                    onClick={() => {
                        setShowSecondInsured(!showSecondInsured);
                        if (showSecondInsured) {
                            clearSecondInsured();
                        }
                    }}
                />
                <Divider />
                {showSecondInsured && (
                    <>
                        <Form.Group widths="equal">
                            <Form.Input
                                label="Insured 2 First Name"
                                onChange={(e) => setClientPolicyUpload({ ...clientPolicyUpload, Insured2FirstName: e.target.value })}
                                required
                                value={clientPolicyUpload.Insured2FirstName}
                            />
                            <Form.Input
                                label="Insured 2 Last Name"
                                onChange={(e) => setClientPolicyUpload({ ...clientPolicyUpload, Insured2LastName: e.target.value })}
                                required
                                value={clientPolicyUpload.Insured2LastName}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                required
                                fluid
                                label="Insured 2 Date of Birth"
                                min={"1900-01-01"}
                                max={today}
                                onChange={(e) => setClientPolicyUpload({ ...clientPolicyUpload, Insured2DOB: e.target.value })}
                                type="date"
                                value={clientPolicyUpload.Insured2DOB || ""}
                            />
                            <NumberFormat
                                required
                                customInput={Form.Input}
                                fluid
                                format="###-##-####"
                                label="Insured 2 SSN"
                                error={insured2SSNError && "Invalid SSN"}
                                mask="_"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setClientPolicyUpload({ ...clientPolicyUpload, Insured2SSN: e.target.value })
                                }
                                placeholder="___-__-____"
                                thousandSeparator
                                value={clientPolicyUpload.Insured2SSN}
                            />
                            <Form.Dropdown
                                required
                                fluid
                                label="Insured 2 Gender"
                                onChange={(e, { value }) => {
                                    setClientPolicyUpload({ ...clientPolicyUpload, Insured2Gender: value as string });
                                    setGender2Error(false);
                                }}
                                error={gender2Error}
                                openOnFocus={false}
                                options={genderDdlOptions}
                                placeholder="Gender"
                                search
                                selection
                                selectOnBlur={false}
                                value={clientPolicyUpload.Insured2Gender}
                            />
                        </Form.Group>
                        <Divider />
                    </>
                )}
                <Grid>
                    <Grid.Row columns="equal">
                        <Grid.Column>
                            <Header size="small" content="File Upload (required)" icon="upload" />
                            <Upload
                                autoUpload={false}
                                batch={false}
                                files={filesToUpload}
                                multiple
                                onAdd={(e) => setFilesToUpload(e.newState)}
                                onBeforeUpload={onBeforeUpload}
                                onProgress={(e) => setFilesToUpload(e.newState)}
                                onRemove={(e) => setFilesToUpload(e.newState)}
                                onStatusChange={handleStatusChange}
                                restrictions={uploadFileRestrictions}
                                saveUrl={config.fileApiUrl}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Header as="h4" content="Uploaded Files" />
                            {!uploadedFiles.length && <p style={{ color: "red" }}>Please upload at least one file</p>}
                            {uploadedFiles.length > 0 && (
                                <div style={{ minHeight: "100px", maxHeight: "200px", overflowY: "scroll" }}>
                                    <List bulleted>
                                        {uploadedFiles.map((file) => {
                                            return <List.Item content={file.FileName} key={file.FileKey} />;
                                        })}
                                    </List>
                                </div>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.TextArea
                                label="Notes"
                                onChange={(e, { value }) => setClientPolicyUpload({ ...clientPolicyUpload, Notes: value as string })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                {duplicatePolicyError && <Message negative content={duplicatePolicyError} style={{ display: "inline-block" }} />}
                <Button compact content="Close" type="button" onClick={handleClose} />
                <Button
                    compact
                    type="submit"
                    content={duplicatePolicyError ? "Submit Duplicate" : "Submit"}
                    color={duplicatePolicyError ? "red" : "blue"}
                    icon="checkmark"
                    loading={loading}
                    disabled={loading || !uploadedFiles.length}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ClientPolicyUploadModal;
