import { Card, Dropdown, Icon, Image, Label, Menu } from "semantic-ui-react";
import React, { useContext } from "react";

import { AppContext } from "./AppContextProvider";
import { Link } from "react-router-dom";
import QuickSearch from "./QuickSearch";
import config from "../config";
import fastLogo from "../images/fast_logo.png";
import lexservLogo from "../images/lexserv_logo.png";
import twentyfirstLELogo from "../images/twentyfirst_logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import useIsServicingUser from "../hooks/useIsServicingUser";

const Navbar: React.FC = () => {
    const { portfolios, unreadMessageCount, permissions } = useContext(AppContext);
    const { user, logout } = useAuth0();
    const isServicingUser = useIsServicingUser();

    return (
        <Menu className="no-print">
            <Menu.Menu position="left">
                <Menu.Item
                    as={Link}
                    to="/"
                    header
                    content={<Image style={{ width: "159px" }} src={lexservLogo} alt="LexServ Logo" />}
                    position="left"
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                />

                <Dropdown item text="Medical Records">
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/medical-records">
                            <Icon name="medkit" color="blue" />
                            Order Management
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/medical-records-insureds">
                            <Icon name="user circle" color="blue" />
                            Insureds
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/add-insured">
                            <Icon name="user plus" color="blue" />
                            Add Insured
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/medrec-search">
                            <Icon name="search" color="blue" />
                            Search
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/document-management">
                            <Icon name="file alternate outline" color="blue" />
                            Document Management
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {isServicingUser && (
                    <>
                        <Dropdown item text="Portfolio">
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/dashboard">
                                    <Icon name="address card outline" color="blue" />
                                    Summary
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/policies">
                                    <Icon name="file text" color="blue" />
                                    Policies
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/insureds">
                                    <Icon name="user circle" color="blue" />
                                    Insureds
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/carrier-ratings">
                                    <Icon name="numbered list" color="blue" />
                                    Carrier Ratings
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/document-management">
                                    <Icon name="file alternate outline" color="blue" />
                                    Document Management
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/report-generator">
                                    <Icon name="chart bar" color="blue" />
                                    Report Generator
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/policy-loans">
                                    <Icon name="suitcase" color="blue" />
                                    Policy Loans
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown item text="Insured">
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/insured-tracking">
                                    <Icon name="user circle outline" color="blue" />
                                    Insured Tracking
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/le-delta">
                                    <Icon name="chart bar" color="blue" />
                                    LE Delta Reporting
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/medical-records">
                                    <Icon name="medkit" color="blue" />
                                    Medical Records Management
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown item text="Activity">
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/death-claims">
                                    <Icon name="clock outline" color="blue" />
                                    Death Claims
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/grace-queue">
                                    <Icon name="alarm" color="blue" />
                                    Grace Queue
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/payment-holds">
                                    <Icon name="list" color="blue" />
                                    Payment Holds
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/policy-opt-queue">
                                    <Icon name="check square" color="blue" />
                                    Policy Optimization
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/policy-upload">
                                    <Icon name="upload" color="blue" />
                                    Policy Upload
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/secure-file-transfer">
                                    <Icon name="exchange" color="blue" />
                                    Secure File Transfer
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                )}
                <Dropdown item text="Other Services">
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <Card link href={config.twentyfirstLEWebUrl} target="_blank">
                                <Image src={twentyfirstLELogo} size="medium" centered style={{ background: "none" }} />
                                <Card.Content extra>TwentyFirst Life Expectancy Reports & Data</Card.Content>
                            </Card>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <Card
                                link
                                href={permissions.CanAccessFasano ? config.fastWebUrl : config.fasanoRegistrationUrl}
                                target="_blank"
                            >
                                <Image src={fastLogo} size="small" centered style={{ background: "none" }} />
                                <Card.Content extra>Fasano Associates Life Expectancy Reports</Card.Content>
                            </Card>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>

            <Menu.Menu position="right">
                {!!portfolios && !!portfolios.length && (
                    <Menu.Item className="widescreen only">
                        <QuickSearch />
                    </Menu.Item>
                )}

                <Menu.Item as={Link} to="/inbox">
                    <Icon name="mail" color="blue" />
                    Inbox
                    <Label circular color="blue" size="small">
                        {unreadMessageCount}
                    </Label>
                </Menu.Item>

                <Dropdown item text={user?.email && user?.email.length > 20 ? `${user.email.substring(0, 20)}...` : user?.email}>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/my-account">
                            <Icon name="user" color="blue" />
                            My Account
                        </Dropdown.Item>
                        {permissions.Admin && (
                            <Dropdown.Item as={Link} to="/user-management">
                                <Icon name="users" color="blue" />
                                User Management
                            </Dropdown.Item>
                        )}
                        {/* <Dropdown.Item as={Link} to="/change-password">
                            <Icon name="key" color="blue" />
                            Change Password
                        </Dropdown.Item> */}
                        <Dropdown.Item onClick={() => logout()}>
                            <Icon name="log out" color="blue" />
                            Log Out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
    );
};

export default Navbar;
